<template>
    <div>
        <ts-page-title
            :title="$t('monthlyOvertime.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('monthlyOvertime.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div>
                    <Button type="info" ghost @click="addNew">{{
                        $t('addNew')
                    }}</Button>
                </div>
                <div class="tw-flex tw-space-x-2">
                    <div>
                        <DatePicker
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            @on-change="onChangeYear"
                        ></DatePicker>
                    </div>
                    <div>
                        <DatePicker
                            type="month"
                            format="MM"
                            placeholder="Select Month"
                            @on-change="onChangeMonth"
                        >
                        </DatePicker>
                    </div>
                    <div>
                        <Input
                            search
                            :placeholder="$t('monthlyOvertime.searchEmployee')"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            ></Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                width="800px"
                :title="$t('monthlyOvertime.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>
<script>
import FromAction from './form.vue'
import { mapState } from 'vuex'
export default {
    name: 'monthly-over-time',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            width: 'width: 300px',
            showForm: false
        }
    },
    computed: {
        ...mapState('payroll/monthlyOvertime', ['resources', 'pagination']),

        columns () {
            return [
                {
                    title: this.$t('monthlyOvertime.cardId'),
                    key: 'card_id',
                    sortable: true
                },
                {
                    title: this.$t('monthlyOvertime.employeeName'),
                    slot: 'employee_name_en',
                    sortable: true
                },
                {
                    title: this.$t('monthlyOvertime.otDate'),
                    key: 'ot_date',
                    sortable: true
                },
                {
                    title: this.$t('monthlyOvertime.timeFrom'),
                    key: 'time_from',
                    sortable: true
                },
                {
                    title: this.$t('monthlyOvertime.timeTo'),
                    key: 'time_to',
                    sortable: true
                },
                {
                    title: this.$t('monthlyOvertime.break'),
                    key: 'break',
                    sortable: true
                },
                {
                    title: this.$t('monthlyOvertime.remark'),
                    key: 'remarks',
                    sortable: true
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    align: 'center',
                    fixed: 'right',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        addNew () {
            this.showForm = true
            this.$refs.form_action.fetchResource()
            this.$refs.form_action.clearInput()
        },
        clearEdit () {
            this.showForm = false
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/monthlyOvertime/fetch', attributes)
                .catch(error => {
                    this.loading = false
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onChangeYear (year) {
            this.year = year
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_year: year,
                    cycle_month: this.month
                }
            })
        },
        onChangeMonth (month) {
            this.month = month
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_month: month,
                    cycle_year: this.year
                }
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
