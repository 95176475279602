var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"demo-spin-article"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyOvertime.employeeName')))]),_c('div',[_c('Select',{class:{
                        'is-invalid': _vm.errors.has('employee_id')
                    },attrs:{"filterable":"","remote-method":_vm.loadEmployee,"clearable":true},model:{value:(_vm.model.employee_id),callback:function ($$v) {_vm.$set(_vm.model, "employee_id", $$v)},expression:"model.employee_id"}},_vm._l((_vm.employeeProfiles),function(employeeProfile,index){return _c('Option',{key:index,attrs:{"value":employeeProfile.employee_id,"label":employeeProfile.employee_name_en}},[_vm._v(_vm._s(employeeProfile.employee_name_en)+"|"+_vm._s(employeeProfile.employee_name_kh)+" ")])}),1),(_vm.errors.has('employee_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('employee_id'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyOvertime.timeFrom')))]),_c('div',[_c('time-picker',{class:{
                            'is-invalid': _vm.errors.has('time_from')
                        },staticStyle:{"width":"370px"},attrs:{"type":"time","placeholder":"HH:mm","format":"HH:mm","confirm":""},model:{value:(_vm.model.time_from),callback:function ($$v) {_vm.$set(_vm.model, "time_from", $$v)},expression:"model.time_from"}}),(_vm.errors.has('time_from'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('time_from'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyOvertime.timeTo')))]),_c('div',[_c('time-picker',{class:{
                            'is-invalid': _vm.errors.has('time_to')
                        },staticStyle:{"width":"370px"},attrs:{"type":"time","placeholder":"HH:mm","format":"HH:mm","confirm":""},model:{value:(_vm.model.time_to),callback:function ($$v) {_vm.$set(_vm.model, "time_to", $$v)},expression:"model.time_to"}}),(_vm.errors.has('time_to'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('time_to'))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyOvertime.otDate')))]),_c('div',[_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('ot_date')
                        },staticStyle:{"width":"370px"},attrs:{"type":"date","placeholder":"Select Date","format":"dd-MM-yyyy"},on:{"on-change":_vm.cycleDateChange},model:{value:(_vm.model.ot_date),callback:function ($$v) {_vm.$set(_vm.model, "ot_date", $$v)},expression:"model.ot_date"}}),(_vm.errors.has('ot_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('ot_date'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyOvertime.break')))]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.break),expression:"model.break"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.errors.has('break')
                        },attrs:{"type":"number","placeholder":"Overs Time"},domProps:{"value":(_vm.model.break)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "break", $event.target.value)}}}),(_vm.errors.has('break'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('break'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyOvertime.year')))]),_c('div',[_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_year')
                        },staticStyle:{"width":"370px"},attrs:{"type":"year","placeholder":"Select Date","format":"yyyy"},on:{"on-change":_vm.cycleYearChange},model:{value:(_vm.model.cycle_year),callback:function ($$v) {_vm.$set(_vm.model, "cycle_year", $$v)},expression:"model.cycle_year"}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_year'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyOvertime.month')))]),_c('div',[_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_month')
                        },staticStyle:{"width":"370px"},attrs:{"type":"month","placeholder":"Select Date","format":"MM"},on:{"on-change":_vm.cycleMonthChange},model:{value:(_vm.model.cycle_month),callback:function ($$v) {_vm.$set(_vm.model, "cycle_month", $$v)},expression:"model.cycle_month"}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_month'))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-label form-label tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyOvertime.remark')))]),_c('div',[_c('Input',{attrs:{"type":"textarea","rows":5,"placeholder":"Enter something..."},model:{value:(_vm.model.remarks),callback:function ($$v) {_vm.$set(_vm.model, "remarks", $$v)},expression:"model.remarks"}})],1)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel')))]),_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting_new,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('saveAddNew')))]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('save')))])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }